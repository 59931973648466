<template>
    <modal ref="modalDetalleArchivo" titulo="Detalle" guardar tamano="modal-md">
        <div v-if="showData">
            <div class="row mx-0">
                <div class="col-auto">
                    <img :src="icon" width="30px" height="30px" />
                    <span class="ml-2">{{ detalle.nombre }}</span>
                </div>
            </div>
            <div class="row mx-1 mt-3">
                <div class="col">
                    <p class="f-500">Tipo</p>
                    <p class="f-12">{{ detalle.extension }}</p>
                </div>
            </div>
            <div class="row mx-1 mt-3">
                <div class="col">
                    <p class="f-500">Creación</p>
                    <p class="f-12">{{ detalle.created_at | helper-fecha('DD MMMM [de] YYYY') }}</p>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row mx-0">
                <div class="col text-center">
                    <p class="f-13">Cargando información...</p>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return {
            showData: false,
            detalle: {},
            icon: '/img/icons/otros-doc.svg',
            typeImg: [
                'png',
                'jpg',
                'jpeg',
                'svg',
                'webp',
                'gif',
                'tiff',
                'psd',
                'bmp',
                'eps'
            ],
            typeVideo: [
                'mp4',
                'avi',
                'mkv',
                'flv',
                'f4v',
                'swt',
                'mov',
                'wmv',
                'divx',
                'h.264',
                'xvid',
                'rm',
                'avchd',
                'webm',
                'html5',
                'mpeg-2'
            ],
        }
    },

    methods:{
        toggle(data){
            this.detalle = data;
            this.typeIcon(data.extension);
            this.$refs.modalDetalleArchivo.toggle();
            this.showData = true;
        },

        typeIcon(ext){
            if(this.typeImg.includes(ext)){
                return this.icon = '/img/icons/Imagen.svg';
            }
            if(this.typeVideo.includes(ext)){
                return this.icon = '/img/icons/video-1.svg';
            }
            if(ext.toLowerCase() === 'pdf'){
                return this.icon = '/img/icons/pdf.svg';
            }
            return this.icon = '/img/icons/otros-doc.svg';
        }
    }
}
</script>
<style lang="scss" scoped>

</style>